<template>
  <section class="section section--head  section--details-bg">
        <div class="container">
            <div class="article">
                <div class="row" >
                    <div class="col-12 col-xl-9" v-for="publish in contentData" :key="publish.id">
                        <div class="row">
                            
                            <div class="col-12">
                                <video  class="plyr" style="width: 100%;" controls="" controlsList="nodownload">
                                    <source :src="publish.source" type="Video/mp4" size="720">
                                </video>
                                <br>
                                <div class="article__content">
                                    <ul class="list" style="margin-top: 0px;">
                                    <!-- <li><h3>{{ publish.title_ar }}</h3></li>
                                    <li><p> {{ publish.desc_ar }} </p></li>
                                    <li><h6>{{ publish.year }}</h6></li> -->
                                    <!-- <li><h6>1 h 42 min</h6></li>-->
                                    </ul>
                                </div> 
                            </div>
                        </div>
                        <div class="row" style=" padding: 0px 5px; margin-bottom: 8px;">
                            <div class="col-12">
                                <div class="comments__item">
									<div class="comments__autor">
										<!-- <img class="comments__avatar" src="assets/img/avatar.svg" style="width: 50px;height: 50px;" alt=""> -->
										<span class="comments__name" ><h4 style="color: #009b74;">{{ publish.title_ar }}</h4></span>
										<!-- <span class="comments__time" style="color: #046162;margin-right: 5px;" ><h6>{{ publish.year }}</h6></span> -->
									</div>
									<p class="comments__text" style="color: rgb(4, 97, 98);font-family: 'Zain', sans-serif;" >{{ publish.desc_ar }} </p>
								</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-12 col-xl-3"  style="margin-top: 0px;overflow-y: auto;height: 80vh;">
                        <div class="col-lg-12 ">
                            <div class="pm-inner">
                                <div class="gen-more-like">
                        <h5 class="gen-more-title">فيديوهات اخرى</h5>
                                </div>
                            </div>
                        </div>
                        <div class="sidebar sidebar--mt" style="margin-top: 2px;">
                            <div class="row row--grid">
                                <div class="col-12" v-for="publish in getCatByID" :key="publish.id">
                                    <div class="row" style="background: rgba(131, 137, 159, 0.07) none repeat scroll 0% 0%; padding: 0px 5px; margin-bottom: 8px;">
                                        <div class="col-12 col-sm-12 col-md-12 col-xl-12" style="padding-right: 0px;"> 
                                            <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                                <div class="gen-movie-contain">
                                                    <div class="gen-movie-img">
                                                        <img :src="publish.carsoul_img"
                                                            alt="single-video-image">
                                                   
                                                        <div class="gen-movie-action">
                                                            <a @click="getFeed(publish)" class="gen-button">
                                                                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
      <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
    </svg> 
                                                                <!-- <i class="fa fa-play"></i> -->
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="gen-info-contain">
                                                        <div class="gen-movie-info">
                                                            <h3 style="color: #fff;"><a @click="getFeed(publish)">{{ publish.title_ar }}</a></h3>
                                                        </div>
                                                        <div class="gen-movie-meta-holder">
                                                            <ul>
                                                                <li>{{ publish.year }}</li>
                                                                <li>
                                                                    <a @click="getFeed(publish)"><span>{{ publish.name_ar }}</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { HTTP } from '@/Api/http-common';
export default {
    name: 'AppContents',
    props: {
       content_id: String,
    },
    setup(props) {
      const cookie = useCookie()
      const router = useRouter();
      const route = useRoute();
      const contentData = ref([]);
      const getCatByID = ref([]);

      try {
         HTTP.get('ContentById.php?CID='+ props.content_id).then((res) => {
           contentData.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
         HTTP.get('GetContentByCategory.php?cat_id='+cookie.getCookie("cat_id")+'&LIMIT=12&OFFSET=0').then((res) => {
           getCatByID.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
     
      const getFeed = (publish) => {
        // if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("minutes") > 0 ) {
          router.push({ name: "Contents", params: { content_id: publish.id } });
        // } else {
        //   router.push({ name: "Trailer" });
        // }
      };
      return {
        contentData,
        getCatByID,
        getFeed
      };
  },
}
</script>

<style>

</style>